import HttpHandler from "@/handler/HttpHandler";
import HttpConstant from "@/const/HttpConstant";

export default class Novel {
    constructor() {
        this.http = new HttpHandler();
    }

    getTags(data) {
        let url = ' https://www.uaa003.com/api/novel/app/novel/tags';
        return this.http.get(url, data, HttpConstant.APPLICATION_JSON_REQ);
    }

    getCategories(data) {
        let url = 'https://www.uaa003.com/api/novel/app/novel/categories';
        return this.http.get(url, data, HttpConstant.APPLICATION_JSON_REQ);
    }

    getNovel(data){
        let url = 'https://www.uaa003.com/api/novel/app/novel/search';
        return this.http.get(url, data, HttpConstant.FORM_DATA_REQ);
    }

    getNovelChapter(data){
        let url = 'https://www.uaa003.com/api/novel/app/novel/chapter';
        return this.http.get(url, data, HttpConstant.FORM_DATA_REQ);
    }

    getCatalog(id){
        let url = ` https://www.uaa003.com/api/novel/app/novel/catalog/${id}`;
        return this.http.get(url, null, HttpConstant.FORM_DATA_REQ);
    }

    // --- 小说历史记录 ---

    getNovelRecordList(data) {
        let url = '/api/novel/getRecordList';
        return this.http.get(url, data, HttpConstant.FORM_DATA_REQ);
    }

    getRecord(data) {
        let url = '/api/novel/getRecord';
        return this.http.get(url, data, HttpConstant.FORM_DATA_REQ);
    }

    //记录播放记录
    insNovelRecord(data) {
        let url = '/api/novel/insRecord';
        return this.http.post(url, data, HttpConstant.APPLICATION_JSON_REQ);
    }

}